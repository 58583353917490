import React from 'react';

import { App, Helmet } from 'modules/app';
import { Error404 } from 'modules/errorpage'

export default class Error404Page extends React.Component {
  render() {
    return (
      <App hideFooter={true}>
        <Helmet
          route={Error404.route}
        />
        <Error404 />
      </App>
    );
  }
}
